import { isAdminRAdvisor, isRestrictedCustomer } from "../pages/common";


const adminLevelRoutes = [
  "/",
  "/customermgmt",
  "/configuration",
  "/cashladder",
  "/financial",
  "/customerprofile",
  "/assestallocationruletable",
  "/portfoliorule",
  "/adminriskprofile",
  "/loginscrn",
  "/webpage",
  "/categorymgmt"
];

const userLevelRoutes = [
  "/",
  "/webpage",
  "/loginscrn",
  "/dashboard",
  "/goals",
  "/retirement",
  "/healthcare",
  "/wealthtransfer",
  "/education",
  "/vacation",
  "/vehicle",
  "/realestate",
  "/wedding",
  "/emi",
  "/customgoal",
  "/goalscategorize",
  "/financialdetails",
  "/realestatehome",
  "/realestatedetail",
  "/realestatedashboard",
  "/jewelleryhome",
  "/jewellerydetail",
  "/jewellerydashboard",
  "/artantiqueshome",
  "/artantiquesdetail",
  "/artantiquesdashboard",
  "/physicalassethome",
  "/physicalassetdetail",
  "/physicalassetdashboard",
  "/banksavingshome",
  "/banksavingsdetail",
  "/banksavingsdashboard",
  "/fdhome",
  "/fddetailpage",
  "/fddashboard",
  "/gsshome",
  "/gssdetailpage",
  "/gssdashboard",
  "/epfhome",
  "/epfdetails",
  "/epfdashboard",
  "/ppfhome",
  "/ppfdetailspage",
  "/ppfdashboard",
  "/othernonmarkethome",
  "/othernonmarketdetail",
  "/othernonmarketdashboard",
  "/npshome",
  "/npsdetailpage",
  "/npsdashboard",
  "/bondhome",
  "/bonddetailpage",
  "/bonddashboard",
  "/stockshome",
  "/stocksdetailpage",
  "/stocksdashboard",
  "/otherfinancialhome",
  "/otherfinancialdetail",
  "/otherfinancialdashboard",
  "/housingloanhome",
  "/housingloandetail",
  "/housingloandashboard",
  "/vehicleloanhome",
  "/vehicleloandetail",
  "/vehicleloandashboard",
  "/creditcardhome",
  "/creditcarddetail",
  "/creditcarddashboard",
  "/personalloanhome",
  "/personalloandetail",
  "/personalloandashboard",
  "/otherloanhome",
  "/otherloandetail",
  "/otherloandashboard",
  "/annuityhomepage",
  "/annuitydetails",
  "/annuitydashboard",
  "/businesshomepage",
  "/businessdetails",
  "/businessdashboard",
  "/rentalhomepage",
  "/rentaldetails",
  "/rentaldashboard",
  "/insurancehomepage",
  "/insurancedetails",
  "/insurancedashboard",
  "/otherhomepage",
  "/otherincomedetails",
  "/otherincomedashboard",
  "/financial",
  "/riskprofilehome",
  "/riskprofile",
  "/riskprofileend",
  "/assetallocdetails",
  "/customerprofile",
  "/customermgmt",
  "/cashladder",
  "/portfolioassetallocation",
  "/resetpin"
];

const lowLevelRoutes = ["/webpage", "/loginscrn", "/resetpin", "/riskprofile", "/riskprofile/:id", "/resetpin/:id","/privacypolicy"];

function getRoutes() {
  const routes = [];
  const rolePriority = getRolePriority();
  if (rolePriority === 3) {
    routes.push(...lowLevelRoutes);
  }
  if (rolePriority === 2) {
    routes.push(...userLevelRoutes);
  }
  if (rolePriority === 1) {
    routes.push(...adminLevelRoutes, ...userLevelRoutes);
  }
  return routes;
}

function getRolePriority() {
  if (isAdminRAdvisor()) {
    return 1;
  } else if (isRestrictedCustomer()) {
    return 2;
  } else {
    return 3;
  }
}

export { getRoutes };

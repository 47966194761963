import { t } from 'i18next';
import './index.css';

const PrivacyPolicy = ()=>{

    const renderPolicyView=()=>{
        return(
            <div className="pp_servicesView">
        <label className="pp_serviceTitleStyle">Privacy Policy</label>
          <div className="pp_privacyPolicyContent">
            <p className="pp_privacyPolicyText">
              At Lookinglaz Technologies LLP, we prioritize your trust and are committed to safeguarding your personal information. We ensure complete transparency in how we collect, use, and protect your data while giving you control over its usage. Our goal is to enhance your experience and provide personalized solutions tailored to your needs. By using our services or sharing your information with us, you agree to the terms outlined in this Privacy Policy. If you do not agree, please refrain from using our services.
            </p>
            <h3 className="pp_privacyPolicySubtitle">Scope of This Privacy Policy</h3>
            <p className="pp_privacyPolicyText">
              This policy applies to all users of Lookinglaz Technologies LLP's website, platform, or application. It covers:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>Data collection methods</li>
              <li>Purpose of data usage</li>
              <li>Data disclosure practices</li>
              <li>User rights regarding their data</li>
              <li>Data security measures</li>
            </ul>
            <h3 className="pp_privacyPolicySubtitle">Collection of Data (Personal &amp; Non-Personal)</h3>
            <h4 className="pp_privacyPolicySubSubtitle">Personal Data</h4>
            <p className="pp_privacyPolicyText">
              We collect personal information through:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>Registration on our website/application</li>
              <li>Information shared while using our services (financial profile, family background, etc.)</li>
              <li>Features used on our platform</li>
              <li>Cookies and similar tracking technologies</li>
              <li>Access granted to third-party platforms (with consent)</li>
            </ul>
            <p className="pp_privacyPolicyText">
              Personal data collected includes:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>Name, contact details (email, phone number)</li>
              <li>Government IDs (PAN, AADHAAR)</li>
              <li>Date of birth, gender, address</li>
              <li>Banking &amp; financial details</li>
              <li>Employment, income, and family-related information</li>
              <li>KYC details, credit information, and transaction history (with explicit consent)</li>
            </ul>
            <h4 className="pp_privacyPolicySubSubtitle">Non-Personal Data</h4>
            <p className="pp_privacyPolicyText">
              This includes technical and behavioral data (e.g., device information, browsing patterns) to improve our services.
            </p>
            <h3 className="pp_privacyPolicySubtitle">Purpose of Data Collection &amp; Usage</h3>
            <p className="pp_privacyPolicyText">
              We use your data to:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>Provide personalized financial solutions</li>
              <li>Assess risk profiles for better service offerings</li>
              <li>Send relevant updates and communications</li>
              <li>Share (with consent) with affiliates/partners for value-added services</li>
              <li>Comply with legal and regulatory requirements</li>
              <li>Monitor service usage and resolve technical issues</li>
            </ul>
            <h3 className="pp_privacyPolicySubtitle">Disclosure of Your Data</h3>
            <p className="pp_privacyPolicyText">
              We do not share your data with unauthorized entities. However, disclosure may occur in the following cases:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>Regulatory/Government Authorities - As required by law</li>
              <li>Service Providers &amp; Partners - Only with prior consent for service enhancement</li>
              <li>Business Affiliates - For relevant offerings (with user approval)</li>
            </ul>
            <h3 className="pp_privacyPolicySubtitle">Your Rights Over Your Data</h3>
            <p className="pp_privacyPolicyText">
              You have the right to:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>Access, modify, or correct your personal data (subject to regulatory policies)</li>
              <li>Request deletion (where applicable)</li>
              <li>Raise concerns regarding data usage</li>
            </ul>
            <p className="pp_privacyPolicyText">
              For any requests, contact us at the details provided below.
            </p>
            <h3 className="pp_privacyPolicySubtitle">Data Security &amp; Legal Compliance</h3>
            <p className="pp_privacyPolicyText">
              We implement stringent security measures, including:
            </p>
            <ul className="pp_privacyPolicyList">
              <li>SSL encryption for secure data transmission</li>
              <li>User responsibility: Protect login credentials and report suspected breaches immediately</li>
            </ul>
            <p className="pp_privacyPolicyText">
              In case of a security concern, change your password and notify us promptly.
            </p>
            <h3 className="pp_privacyPolicySubtitle">Data Retention Period</h3>
            <p className="pp_privacyPolicyText">
              We retain user data only as long as necessary, adhering to legal and regulatory requirements.
            </p>
            <h3 className="pp_privacyPolicySubtitle">Changes to Privacy Policy</h3>
            <p className="pp_privacyPolicyText">
              • Updates will be effective immediately upon posting on our platform.
              • Users are responsible for reviewing changes periodically.
              • Continued use implies acceptance; otherwise, discontinue services and inform us.
            </p>
            <h3 className="pp_privacyPolicySubtitle">Queries &amp; Complaints</h3>
            <p className="pp_privacyPolicyText">
              For any queries or concerns, you may contact our Compliance Officer, Maninder Pal Singh, at <a href="mailto:Maninderpal.singh@fliber.in">Maninderpal.singh@fliber.in</a> (Lookinglaz Technologies LLP)
            </p>
          </div>
      </div>
        );
    }

   const renderFooterView = () => {
       return (
         <div className={'pp_footerView'}>
           <label className={'pp_footerTextStyle'}>{t('Copyright Info')}</label>
         </div>
       );
     }

    return(
        <div className='pp_container'>
            {renderPolicyView()}
            {renderFooterView()}
        </div>
    );   
}

export default PrivacyPolicy;


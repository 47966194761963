import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Popover, Whisper } from "rsuite";
import './index.css';
import * as logger from '../../utils/logger';
import Logo from '../../../assets/svgs/websvgs/logoDark.svg';
import Goal from '../../../assets/svgs/websvgs/goal.svg';
import Insurance from '../../../assets/svgs/websvgs/insurance.svg';
import Investment from '../../../assets/svgs/websvgs/investment.svg';
import Tax from '../../../assets/svgs/websvgs/tax.svg';
import Estate from '../../../assets/svgs/websvgs/estate.svg';
import Diversified from '../../../assets/svgs/websvgs/diversifiedMgnt.svg';
import Strategic from '../../../assets/svgs/websvgs/strategicAsset.svg';
import Cash from '../../../assets/svgs/websvgs/cashMgnt.svg';
import Family from '../../../assets/svgs/websvgs/family.svg';
import SingleWindow from '../../../assets/svgs/websvgs/singleWindows.svg';
import AnnualReview from '../../../assets/svgs/websvgs/annualMgnt.svg';
import InsuranceScore from '../../../assets/svgs/websvgs/insuranceScore.svg';
import RetirementScore from '../../../assets/svgs/websvgs/retirementScore.svg';
import SustainabilityScore from '../../../assets/svgs/websvgs/sustainabilityScore.svg';
import AboutUs from '../../../assets/svgs/websvgs/aboutUs.svg';
import LinkedIn from '../../../assets/svgs/websvgs/linkedin.svg';
import Team_1 from '../../../assets/images/profile_1.JPG';
import Team_2 from '../../../assets/images/profile_2.JPG';
import LogoLight from '../../../assets/svgs/websvgs/logoLight.svg';
import Whatsapp from '../../../assets/svgs/websvgs/whatsapp.svg';
import Phone from '../../../assets/svgs/websvgs/phone.svg';
import Email from '../../../assets/svgs/websvgs/email.svg';
import Website from '../../../assets/svgs/websvgs/website.svg';
import Location from '../../../assets/svgs/websvgs/location.svg';
import Facebook from '../../../assets/svgs/websvgs/fb.svg';
import Insta from '../../../assets/svgs/websvgs/insta.svg';
import Twitter from '../../../assets/svgs/websvgs/twitter.svg';
import YouTube from '../../../assets/svgs/websvgs/youtube.svg';
import LinkedInLight from '../../../assets/svgs/websvgs/linkedinLight.svg';
import { Button } from "../../components";
import RRScoreModal from "../scoremodals/rrscore";
import * as Bl from "../../../businesslogic";
import SustainabilityScoreModal from "../scoremodals/sustainabilityscore";
import Testimonials from "../testimonials";
import Banners from "../banners";
import EnquiryModal from "../supportmodals/enquirymodal";
import ReportModal from "../supportmodals/reportmodal";
import ContactModal from "../supportmodals/contactmodal";
import InsuranceScoreModal from "../scoremodals/insurancescore";
import { resetSelectedUserDetail, resetUserCashLadderDetail, resetUserDetail } from "../../common";
import { AgGridReact } from "ag-grid-react";
import { FaBars, FaTimes } from 'react-icons/fa';


const WebPageScrn = (props) => {
  const [activeView, setActiveView] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [rowDisposalData, setRowDisposalData] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [rowYearlyDisposal,setRowYearlyDisposal] = useState([]);
  const [state, setState] = useState({
    showRRScoreModal: false,
    showSustainScoreModal: false,
    showEnquiryModal: false,
    showReportModal: true,
    showContactModal: false,
    showInsuranceScoreModal: false
  });
  let supportViewRef = useRef(null);
  const introViewRef = useRef(null);
  const serviceViewRef = useRef(null);
  const aboutViewRef = useRef(null);

  useEffect(() => {
    resetUserDetail();
    resetSelectedUserDetail();
    resetUserCashLadderDetail();

    setTimeout(() => {
      hideReportModal();
    }, 5000);
  }, [])

  const onClickHome = () => {
    if (introViewRef?.current) {
      introViewRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveView(null);
      setIsMenuOpen(false);
    }
  }

  const onClickService = () => {
    if (serviceViewRef?.current) {
      serviceViewRef.current.scrollIntoView({ behavior: "smooth" });
      setActiveView(null);
      setIsMenuOpen(false);
    }
  }

  const onClickPrivacyPolicy=()=>{
    window.open("/privacypolicy", "_blank");
  }

  const onClickAboutUs = () => {    
     setActiveView(null);
    setTimeout(() => {
    if (aboutViewRef.current) {
      aboutViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);           
      setIsMenuOpen(false);    
  };

  
  const onPressClientLogin = () => {
    props.navigate("/loginscrn")
  }

  const onPressGetInTouch = () => {
    setState((prevState) => ({
      ...prevState,
      showContactModal: true
    }));
  }

  const onPressGetStarted = () => {
    setState((prevState) => ({
      ...prevState,
      showContactModal: true
    }));
  }

  const onPressInsuranceScore = () => {
    setState((prevState) => ({
      ...prevState,
      showInsuranceScoreModal: true
    }));
  }

   const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const onPressRRScore = () => {
    setState((prevState) => ({
      ...prevState,
      showRRScoreModal: true
    }));
  }

  const onPressSustainScore = () => {
    setState((prevState) => ({
      ...prevState,
      showSustainScoreModal: true
    }));
  }

  const onSelectEnquiry = () => {
    hidePopOver();
    setState((prevState) => ({
      ...prevState,
      showEnquiryModal: true
    }))
  }

  const onSelectReport = () => {
    hidePopOver();
    setState((prevState) => ({
      ...prevState,
      showReportModal: true
    }))
  }

  const hideReportModal = () => {
    setState((prevState) => ({
      ...prevState,
      showReportModal: false
    }))
  }

  const hideEnquiryModal = () => {
    setState((prevState) => ({
      ...prevState,
      showEnquiryModal: false
    }))
  }

  const hideContactModal = () => {
    setState((prevState) => ({
      ...prevState,
      showContactModal: false
    }))
  }

  const hideInsuranceScoreModal = () => {
    setState((prevState) => ({
      ...prevState,
      showInsuranceScoreModal: false
    }))
  }

  const hideRRScoreModal = () => {
    setState((prevState) => ({
      ...prevState,
      showRRScoreModal: false
    }))
  }

  const hideSustainScoreModal = () => {
    setState((prevState) => ({
      ...prevState,
      showSustainScoreModal: false
    }))
  }


const columnDefs = [
  {
    headerName: 'Received From',
    field: 'interactionsource',
    flex: 2, 
    minWidth: 150, 
    resizable: true,
  },
  {
    headerName: 'Pending at the end of last month',
    field: 'Pending_Last_Month',
    cellRenderer: ({ data }) => <label>{data.Pending_Last_Month}</label>,
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Pending at the end of the month',
    field: 'Pending_End_Of_Month',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Received',
    field: 'Received',
    flex: 1,
    minWidth: 100,
    resizable: true,
     cellStyle: { textAlign: 'center' }
    
  },
  {
    headerName: 'Resolved',
    field: 'Resolved',
    flex: 1,
    minWidth: 100,
    resizable: true,
     cellStyle: { textAlign: 'center' },
  },
  {
    headerName: 'Total Pending',
    field: 'Total_Pending',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Pending Complaints > 3',
    field: 'Pending_Complaints_3_Months',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  }
];



const columnMonthDefs = [
  {
    headerName: 'Sr. No.',
    valueGetter: 'node.rowIndex + 1',
    flex: 1, 
    minWidth: 50, 
    resizable: true,
  },
  {
    headerName: 'Month',
    field: 'Month',
    flex: 2, 
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: 'Carried forward from previous month',
    field: 'Carried_Forward',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Received',
    field: 'Received',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Resolved',
    field: 'Resolved',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Pending',
    field: 'Pending',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  }
];


const columnYearlyDefs = [
  {
    headerName: 'Sr. No.',
    valueGetter: 'node.rowIndex + 1', 
    flex: 1, 
    minWidth: 50, 
    resizable: true,
  },
  {
    headerName: 'Year',
    field: 'Year',
    flex: 2,
    minWidth: 150,
    resizable: true,
  },
  {
    headerName: 'Carried forward from previous year',
    field: 'Carried_Forward',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Received',
    field: 'Received',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Resolved',
    field: 'Resolved',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  },
  {
    headerName: 'Pending',
    field: 'Pending',
    flex: 1,
    minWidth: 100,
    resizable: true,
    cellStyle: { textAlign: 'center' }
  }
];


  const hidePopOver = () => {
    if (supportViewRef) {
      supportViewRef.close();
    }
  }

  const renderLogoView = () => {
    return (
      <div className={"wp_logoView"}>
        <img src={Logo} alt={'Logo'} className={"wp_logoStyle"} />
      </div>
    );
  }

  const renderNavLinkView = (label, onChange) => {
    return (
      <div
        className={"wp_navLinkView"}
        onClick={onChange}
      >
        <label className={"wp_navLinkStle"}>{label}</label>
      </div>
    );
  }
const getMonthEndingComplaints = () => {
  const fetchComplaints = async () => {
    try {
      const responses = await Bl.Support.getMonthEndingComplaints();
      const complaints = responses?.data || [];    
      setRowData(complaints);
    } catch (error) {
      console.error('Error fetching complaints:', error);
      setRowData([]);
    }
  };

  const fetchDisposalTrend = async () => {
    try {
      const response = await Bl.Support.getMonthlyDisposalTrend();
      const disposalData = response?.data || [];    
      setRowDisposalData(disposalData);
    } catch (error) {
      console.error('Error fetching disposal trend:', error);
      setRowDisposalData([]);
    }
  };
   const fetchYearlyDisposalTrend = async () => {
    try {
      const response = await Bl.Support.getYearlyDisposalTrend();
      const yearlyDisposalData = response?.data || [];      
      setRowYearlyDisposal(yearlyDisposalData);
    } catch (error) {
      console.error('Error fetching disposal trend:', error);
      setRowDisposalData([]);
    }
  };

  fetchComplaints();
  fetchDisposalTrend();
  fetchYearlyDisposalTrend();
};





  const renderPopOverView = () => {
  return (
    <Popover className="wp_popoverView" onClose={hidePopOver}>
      <div
        className="wp_popupItemView"
        onClick={() => {
          setActiveView('InvestorCharter');
          getMonthEndingComplaints();
          setIsMenuOpen(false);
          hidePopOver(); 
          serviceViewRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
      >
        <label className="wp_popupItemStyle">{t('Investor Charter')}</label>
      </div>
      <div
        className="wp_popupItemView"
        onClick={() => {
          setActiveView('GrievanceRedressal');
          setIsMenuOpen(false);
          hidePopOver();
          serviceViewRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
      >
        <label className="wp_popupItemStyle">{t('Grievance Redressal Mechanism')}</label>
      </div>
      <div
        className="wp_popupItemView"
        onClick={() => {
          setActiveView('ComplaintsHistory');
          getMonthEndingComplaints();
          setIsMenuOpen(false);
          hidePopOver(); 
          serviceViewRef.current?.scrollIntoView({ behavior: "smooth" });
        }}
      >
        <label className="wp_popupItemStyle">{t('Complaints History')}</label>
      </div>
    </Popover>
  );
};

  const renderPopupView = () => {
    return (
      <Whisper
        placement="bottom"
        speaker={renderPopOverView()}
        trigger="click"
        ref={(ref) => (supportViewRef = ref)}
      >
        <label className={"wp_navLinkStle"}>{t('Investor')}</label>
      </Whisper>
    );
  }

  const renderNavigationLinks = () => {
    return (
      <div className="wp_navView">
        {renderNavLinkView(t('Home'), onClickHome)}
        {renderNavLinkView(t('Our Services'), onClickService)}
        {renderNavLinkView(t('About us'), onClickAboutUs)}
        {renderPopupView()}
      </div>
    );
  }

  const renderLinkView = () => {
    return (
      <div className="wp_linkView" onClick={onPressClientLogin}>
        <label className={"wp_linkStyle"}>
          {t('Client Login')}
        </label>
      </div>
    );
  }

  const renderButtonView = (buttonName, onPressButton) => {
    return (
      <Button
        buttonName={buttonName}
        onPressButton={onPressButton}
      />
    );
  }

  const renderLinkNButtonView = () => {
    return (
      <div className="wp_linkNbuttonView">
        {renderLinkView()}
        {renderButtonView(t('Get In Touch'), onPressGetInTouch)}
      </div>
    );
  }

  const renderHeaderView = () => {
      return (
    <div className="wp_headerView">     
      <div className="wp_burgerMenu" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />} 
      </div>      
      {renderLogoView()}    
      <div className={`wp_navigationLinks ${isMenuOpen ? 'open' : ''}`}>
        {renderNavigationLinks()}
      </div>  
      {renderLinkNButtonView()}
    </div>
  );
};

  const renderBannersView = () => {
    return (
      <div
        ref={(ref) => introViewRef.current = ref}
        className="wp_bannersView"
      >
        <Banners
          onPressGetStarted={onPressGetStarted}
        />
      </div>
    );
  }

  const renderServiceItemView = (source, label) => {
    return (
      <div className="wp_itemView">
        <img className="wp_itemStyle" src={source} alt={label} />
        <label className="wp_itemTextStyle">{label}</label>
      </div>
    );
  }

 const renderInvestorCharterView = () => {
    return(
        <div
      ref={(ref) => serviceViewRef.current = ref}
      className="wp_servicesView"
    >
      <label className="wp_serviceTitleStyle">Investor Charter</label>      
       <div className="investorCharterContent">
         <section className="investorCharterSection">
      <h2 className="investorCharterSubtitle">A. Vision and Mission Statements for Investors</h2>
      <div className="investorCharterContent">
        <div className="visionMissionContainer">
          <div className="visionBlock">
            <h3 className="visionTitle">Vision</h3>
            <p className="visionText">Invest with knowledge & safety.</p>
          </div>
          <div className="missionBlock">
            <h3 className="visionTitle">Mission</h3>
            <p className="missionText">Every investor should be able to invest in the right investment products based on their needs, manage, and monitor them to meet their goals, access reports, and enjoy financial wellness.</p>
          </div>
        </div>
      </div>
    </section>        
        <section className="investorCharterSection">
          <h2 className="investorCharterSubtitle">B. Details of Business Transacted by the Investment Adviser with Respect to the Investors</h2>
          <ul className="investorCharterList">
            <li>To enter into an agreement with the client providing all details including fee details, aspects of Conflict-of-interest disclosure, and maintaining confidentiality of information.</li>
            <li>To do a proper and unbiased risk-profiling and suitability assessment of the client.</li>
            <li>To obtain registration with Know Your Client Registration Agency (KRA) and Central Know Your Customer Registry (CKYC).</li>
            <li>To conduct an audit annually.</li>
            <li>To disclose the status of complaints on its website.</li>
            <li>To disclose the name, proprietor name, type of registration, registration number, validity, complete address with telephone numbers, and associated SEBI regional/local Office details on its website.</li>
            <li>To employ only qualified and certified employees.</li>
            <li>To deal with clients only from the official number.</li>
            <li>To maintain records of interactions with all clients including prospective clients (prior to onboarding), where any conversation related to advice has taken place.</li>
          </ul>
        </section>        
        <section className="investorCharterSection">
          <h2 className="investorCharterSubtitle">C. Details of Services Provided to Investors</h2>
          <ul className="investorCharterList">
            <li>Onboarding of Clients</li>
            <li>Sharing of agreement copy</li>
            <li>Completing KYC of clients</li>
            <li>Disclosure to Clients</li>
            <li>To provide full disclosure about its business, affiliations, compensation in the agreement.</li>
            <li>To not access client’s accounts or holdings for offering advice.</li>
            <li>To disclose the risk profile to the client.</li>
            <li>To provide investment advice to the client based on the risk-profiling of the clients and suitability of the client.</li>
          </ul>
        </section>        
        <section className="investorCharterSection">
          <h2 className="investorCharterSubtitle">D. Details of Grievance Redressal Mechanism and How to Access It</h2>
          <ol className="investorCharterOrderedList">
            <li>In case of any grievance/complaint, an investor should approach the concerned Investment Adviser and ensure that the grievance is resolved within 30 days.</li>
            <li>If the investor’s complaint is not redressed satisfactorily, one may lodge a complaint with SEBI on SEBI’s ‘SCORES’ portal, a centralized web-based complaints redressal system. SEBI takes up the complaints registered via SCORES with the concerned intermediary for timely redressal. SCORES facilitates tracking the status of the complaint.</li>
            <li>For physical complaints, investors may send their complaints to: Office of Investor Assistance and Education, Securities and Exchange Board of India, SEBI Bhavan, Plot No. C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai – 400 051.</li>
          </ol>
        </section>        
        <section className="investorCharterSection">
          <h2 className="investorCharterSubtitle">E. Expectations from the Investors (Responsibilities of Investors)</h2>
          <h3 className="investorCharterSubtitle">Do’s</h3>
          <ul className="investorCharterList">
            <li>Always deal with SEBI registered Investment Advisers.</li>
            <li>Ensure that the Investment Adviser has a valid registration certificate.</li>
            <li>Check for SEBI registration number. Please refer to the list of all SEBI registered Investment Advisers which is available on SEBI website in the following link: <a href="https://www.sebi.gov.in/sebiweb/home/HomeAction.do?doList=RecognisedIntermediaries" target="_blank" rel="noopener noreferrer" className="investorCharterLink">SEBI | Recognised Intermediaries</a></li>
            <li>Pay only advisory fees to your Investment Adviser. Make payments of advisory fees through banking channels only and maintain duly signed receipts mentioning the details of your payments.</li>
            <li>Always ask for your risk profiling before accepting investment advice. Insist that Investment Adviser provides advisory strictly on the basis of your risk profiling and take into account available investment alternatives.</li>
            <li>Ask all relevant questions and clear your doubts with your Investment Adviser before acting on advice.</li>
            <li>Assess the risk–return profile of the investment as well as the liquidity and safety aspects before making investments.</li>
            <li>Insist on getting the terms and conditions in writing duly signed and stamped. Read these terms and conditions carefully particularly regarding advisory fees, advisory plans, category of recommendations, etc., before dealing with any Investment Adviser.</li>
            <li>Be vigilant in your transactions.</li>
            <li>Approach the appropriate authorities for redressal of your doubts/grievances.</li>
            <li>Inform SEBI about Investment Advisers offering assured or guaranteed returns.</li>
          </ul>          
          <h3 className="investorCharterSubtitle">Don’ts</h3>
          <ul className="investorCharterList">
            <li>Don’t fall for stock tips offered under the pretext of investment advice.</li>
            <li>Do not provide funds for investment to the Investment Adviser.</li>
            <li>Don’t fall for the promise of indicative or exorbitant or assured returns by the Investment Advisers. Don’t let greed overcome rational investment decisions.</li>
            <li>Don’t fall prey to luring advertisements or market rumors.</li>
            <li>Avoid doing transactions only on the basis of phone calls or messages from any Investment Adviser or its representatives.</li>
            <li>Don’t take decisions just because of repeated messages and calls by Investment Advisers.</li>
            <li>Do not fall prey to limited period discounts or other incentives, gifts, etc., offered by Investment Advisers.</li>
            <li>Don’t rush into making investments that do not match your risk-taking appetite and investment goals.</li>
            <li>Do not share login credentials and passwords of your trading and demat accounts with the Investment Adviser.</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

   const renderGrievanceRedressalView = () => {
    return(
     <div
      ref={(ref) => serviceViewRef.current = ref}
      className="grievanceContent"
    >
      <label className="wp_serviceTitleStyle">Grievance Redressal Mechanism</label>      
      <div  className="grievanceRedressalSection">
        <p>
          In case of any grievance/complaint, investor can open a complaint ticket on our website
          (through the “Get In Touch” section) or reach us on our email <a href="mailto:info@fliber.in" className="emailLink">info@fliber.in</a>. We shall
          ensure that the grievance is addressed within 15 days from the date of receipt of the
          grievance.
        </p>
        <p>
          In case of any delay beyond 15 days in resolving your grievance as received above, we
          shall communicate the same to you.
        </p>
        <p>
          In case, you are not satisfied with our response, you can lodge your grievances with SEBI
          at <a href="https://www.sebi.scores.gov.in" target="_blank" rel="noopener noreferrer" className="externalLink">www.sebi.scores.gov.in</a> or you may also write to any of the offices of SEBI. For any
          queries, feedback, or assistance, please contact SEBI Office Toll-Free
          Helpline at <a href="tel:1800227575" className="phoneLink">1800 22 7575</a> / <a href="tel:18002667575" className="phoneLink">1800 266 7575</a>.
        </p>
        <p>
          Your attention is drawn to the SEBI circular no. SEBI/HO/OIAE/OIAE_IAD-1/P/CIR/2023/131 dated July 31, 2023, on “Online Resolution of Disputes in the Indian
          Securities Market”. A common Online Dispute Resolution Portal (“ODR Portal”), which
          harnesses conciliation and online arbitration for resolution of disputes arising in the
          Indian Securities Market, has been established.
        </p>
        <p>
          ODR Portal can be accessed via the following link – <a href="https://smartodr.in" target="_blank" rel="noopener noreferrer" className="externalLink">https://smartodr.in</a>
        </p>
      </div>
    </div>
    )
  }

  const renderComplaintsHistoryView = () => {
  return (
    <div
      ref={(ref) => (serviceViewRef.current = ref)}
      className="wp_servicesView"
    >
      <label className="wp_serviceTitleStyle">{t('Complaints History')}</label>
      <div className="rmh_tableView">
        <div className="ag-theme-alpine grid-container">
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
        
        <div className="ag-theme-alpine grid-container">
          <label className="grid-header">Trend of monthly disposal of complaints</label>
          <AgGridReact
            rowData={rowDisposalData}
            columnDefs={columnMonthDefs}
            defaultColDef={{
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
          <label className="grid-header-sub">*Inclusive of complaints of previous months resolved in the current month, #inclusive of complaints pending as on the last day of the month.</label>
        </div>

        <div className="ag-theme-alpine grid-container">
          <label className="grid-header">Trend of annual disposal of complaints</label>
          <AgGridReact
            rowData={rowYearlyDisposal}
            columnDefs={columnYearlyDefs}
            defaultColDef={{
              wrapHeaderText: true,
              autoHeaderHeight: true,
            }}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
          <label className="grid-header-sub">*Inclusive of complaints of previous years resolved in the current year, #inclusive of complaints pending as on the last day of the year.</label>
        </div>
      </div>
    </div>
  );
};


  const renderFinancialContentView = () => {
    return (
      <div className="wp_financialContentView">
        <label className="wp_serviceSubHeadingStyle">{t('Financial Planning')}</label>
        <label className="wp_serviceSubLabelStyle">{t('powered by Fliber Investment Advisor')}</label>
         <div className="wp_wrapper">
             <label className="wp_serviceLabelStyle">
          {t('About Financial Planning')}
        </label>
         </div>       
        <div className="wp_itemsOuterView">
          {renderServiceItemView(Goal, t('Goal Planning'))}
          {renderServiceItemView(Insurance, t('Insurance Planning'))}
          {renderServiceItemView(Investment, t('Investment Planning'))}
          {renderServiceItemView(Tax, t('Tax Planning'))}
          {renderServiceItemView(Estate, t('Estate Planning'))}
          {renderServiceItemView(Estate, t('Estate Planning'))}
        </div>
      </div>
    );
  }

  const renderWealthContentView = () => {
    return (
      <div className="wp_wealthContentView">        
         <label className="wp_serviceSubHeadingStyle">{t('Wealth Management')}</label>
        <label className="wp_serviceSubLabelStyle">{t('powered by Fliber Wealth')}</label>
        <div className="wp_wrapper">
        <label className="wp_serviceLabelStyle">
          {t('About Wealth Management')}
        </label>
        </div>       
        <div className="wp_itemsOuterView">
            {renderServiceItemView(Diversified, t('Diversified Investment Management & Analysis'))}
            {renderServiceItemView(Strategic, t('Strategic Asset Planning'))}
            {renderServiceItemView(Cash, t('Cash Management'))}
            {renderServiceItemView(Family, t('Family Philanthrophy'))}
            {renderServiceItemView(SingleWindow, t('Single Windows'))}
            {renderServiceItemView(AnnualReview, t('Annual Management Review'))}
        </div>
      </div>
    );
  }

  const renderServicesView = () => {
    return (
      <div
        ref={(ref) => serviceViewRef.current = ref}
        className="wp_ourservicesView">
        <label className="wp_serviceTitleStyle">{t('Our Services')}</label>
        <div className="wp_serviceContentView">
          {renderFinancialContentView()}
          {renderWealthContentView()}
        </div>
      </div>
    );
  }

  const renderTestItemView = (source, title, desc, link, style, onClick) => {
    return (
      <div className={style} onClick={onClick}>
        <img className="wp_testItemStyle" src={source} alt={title} />
        <label className="wp_itemTitleStyle">{title}</label>
        <label className="wp_itemDescStyle">{desc}</label>
        <label className="wp_itemLinkStyle">{link}</label>
      </div>
    );
  }

  const renderTestScoreView = () => {
    return (
      <div className="wp_testScoreView">
        <label className="wp_serviceTitleStyle">{t('Test your Score')}</label>
        <div className="wp_testContentsView">
          {renderTestItemView(
            InsuranceScore,
            t('Insurance Coverage'),
            t('Determine how financially secure the people close to you would be in times of emergencies'),
            t('Calculate your Insurance score'),
            'wp_testItemView_1',
            onPressInsuranceScore
          )}
          {renderTestItemView(
            RetirementScore,
            t('Retirement Readiness Score'),
            t('Check if you are financially ready to start the second innings of your life'),
            t('Calculate Retirement readiness score'),
            'wp_testItemView_2',
            onPressRRScore
          )}
          {renderTestItemView(
            SustainabilityScore,
            t('Sustainability Score'),
            t('Analyze if your retirement corpus is good enough for a comfortable retired life.'),
            t('Calculate your Sustainability Score'),
            'wp_testItemView_3',
            onPressSustainScore
          )}
        </div>
      </div>
    );
  }

  const renderAboutUsView = () => {
    return (
      <div
        ref={(ref) => aboutViewRef.current = ref}
        className="wp_aboutUsView">
        <label className="wp_serviceTitleStyle">{t('About Us')}</label>
        <div className="wp_aboutContentView">
          <div className="wp_aboutDescView">
            <label className="wp_aboutDescStyle">
              {'Fliber® is the registered trademark of Lookinglaz Technologies LLP, a SEBI Registered Investment Advisor and AMFI registered Mutual Fund Distributor. At Lookinglaz we help individuals and families to plan their financial journey and achieve their goals with personalized & unbiased advice in the most simplified manner. Our curated advisory services are backed by 50+ years of collective industry experience, deep research capabilities and our state of art technology tools.'}
            </label>
            <label className="wp_aboutDescStyle">
              {'We aim to become your most trusted partner in every stage of your financial journey by providing fair and affordable wealth management solutions. Our approach to financial planning is a blend of transparency and simplicity and equips our customers with proactive unbiased advice to achieve their financial freedom.'}
            </label>
            <label className="wp_aboutDescStyle">
              {'What’s More! You can ride on the most technologically advanced and revolutionary financial tool developed by us to help you visualize your financial journey in its entirety'}
            </label>
          </div>
          <img src={AboutUs} alt="About us"  className="abt_img"/>
        </div>
      </div>
    );
  }

  const renderLeaderView = (source, name, link, desc) => {
    return (
      <div className="wp_leaderView">
        <div className="wp_leaderImgView">
          <img src={source} alt={name} className="wp_leaderImgStyle"></img>
        </div>
        <div className="wp_nameView">
          <label className="wp_nameStyle">{name}</label>
          <img src={LinkedIn} alt={'Linked in'} className="wp_linkedinStyle"></img>
        </div>
        <label className="wp_nameDescStyle">{desc}</label>
      </div>
    );
  }

  const renderTeamView = () => {
    return (
      <div className="wp_teamView">
        <label className="wp_serviceTitleStyle">{t('Our Leadership Team')}</label>
        <div className="wp_teamContentView">
          {renderLeaderView(
            Team_1,
            t('Vikram Biswas (CFP)'),
            '',
            t('About Vikram'))
          }
          {renderLeaderView(
            Team_2,
            t('Maninder Pal Singh (CFP)'),
            '',
            t('About Manindar'))
          }
        </div>
      </div>
    );
  }

  const renderTestimonialsView = () => {
    return (
      <div className="wp_testimonialsView">
        <label className="wp_serviceTitleStyle">{t('Testimonials')}</label>
        <div className="wp_testimonialContentView">
          <Testimonials />
        </div>
      </div>
    );
  }

 const renderContactTopView = () => {
  return (
    <div className="contact-footer-container">
      <div className="company-details">
        <img src={LogoLight} alt="Fliber Logo" className="company-logo" />
        <label className="company-text">Fliber® is a registered trademark of Lookinglaz Technologies LLP.</label>
        <label className="company-text">{t('Fliber Contact Info')}</label>
        <label className="company-text">{t('Fliber Contact subInfo')}</label>
        <label className="company-registration">
          SEBI Registration No.: INA200016917 | BASL-Membership No: 2084 | ARN:275255.
        </label>
      </div>
    </div>
  );
}
  const renderContactItemView = (source, label, value) => {
  const isEmail = value && value.includes('@');
  return (
    <tr className="footer-contact-row">
      <td>
        <label className="footer-label color_text">{label}:</label>
      </td>
      <td>
        {isEmail ? (
          <a href={`mailto:${value}`} className="footer-contact-value footer-link-text">{value}</a>
        ) : (
          <label className="footer-contact-value">{value}</label>
        )}
      </td>
    </tr>
  );
};

  const KeyPersonsTable = () => {
  const keyPersons = [
    {
      name: 'Vikram Biswas',
      designation: 'Principal Officer',
      email: 'Vikram.biswas@fliber.in',
      contactNumber: '9886767218',
    },
    {
      name: 'Maninder Pal Singh',
      designation: 'Compliance & Grievance Officer',
      email: 'Maninderpal.singh@fliber.in',
      contactNumber: '9379906251',
    },
  ];

  return (
    <div  className="keypersons-table"> 
<label style={{marginBottom:'10px'}} className="wp_contactLableStyle color_text">Name of Key Persons</label>
<table>

      <thead className="table_head">
        <tr>
          <th>Name</th>
          <th>Designation</th>
          <th>Email</th>
          <th>Contact Number</th>
        </tr>
      </thead>
      <tbody>
        {keyPersons.map((person, index) => (
          <tr key={index}>
            <td>{person.name}</td>
            <td>{person.designation}</td>
            <td>
              <a href={`mailto:${person.email}`}>{person.email}</a>
            </td>
            <td>{person.contactNumber}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
      
  );
};

const renderContactDetailsView = () => {
  const address = `5th Floor, #1296, 4th Main, 18th Cross, Sector -7, HSR Layout , Bangalore 560102.`;
  const sebiAddress = (
  <>
    Plot No.C4-A, 'G' Block Bandra-Kurla Complex, Bandra (East),
    Mumbai - 400051, Maharashtra
  </>
);

  return (
    <div className="wp_contactDetailsView">
      <div className="wp_contactItemView">
        <table>
          <tbody>
            
            <div style={{ marginBottom: '24px'}}>
              {renderContactItemView(Phone, t('Registration Type'), ' Non-Individual')}             
            </div>
            <div style={{ marginBottom: '24px', display: 'flex' ,alignItems: 'center',justifyContent: 'center',gap:'2rem' }}>
              {renderContactItemView(Phone, t('Phone Nos'), '9886767218, 9379906251')}
              {renderContactItemView(Email, t('Email'), 'info@fliber.in')}
            </div>
            <div className="footer-section">
  <div className="footer-item">
    <label className="footer-label color_text">{t('Registered Office:')}</label>
    <label className="footer-value color_text">{address}</label>
  </div>

  <div className="footer-item flex-row">
    <label className="footer-label color_text">{t('Website')}:</label>
    <a href="https://www.fliber.in" className="footer-value link-text">www.fliber.in</a>
  </div>

  {KeyPersonsTable()}

  <div className="footer-item">
    <label className="footer-label color_text">{t('SEBI Contact :')}</label>
    <label className="footer-address color_text">
      {t('SEBI Bhavan BKC; Address:')} {sebiAddress}; {t('Tel')}: +91-22-26449000
    </label>
  </div>

  <div className="footer-item item-column">
    <label className="footer-warning-text">{t('Standard Warning')}</label>
    <p className="footer-warning-value color_text">
      {t('Investments in securities market are subject to market risks, read all the related documents before investing.')}
    </p>
  </div>

  <div className="footer-item item-column">
    <label className="footer-warning-text">{t('Disclaimer')}</label>
    <p className="footer-warning-value color_text">
      {t('Registration granted by SEBI, membership of BASL and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to the investors.')}
    </p>
  </div>

  <div className="footer-item item-column">
    <label className="footer-link-text" onClick={onClickPrivacyPolicy}>
      Privacy Policy
    </label>
  </div>

</div>
          </tbody>            
        </table>
      </div>
    </div>
  );
};
  
  const renderFollowUsItemView = (source, label, link) => {
    return (
      <div className="wp_followItemView">
        <img src={source} alt={label} className="wp_followIconStyle" />
        <label className="wp_followLabelStyle">{label}</label>
      </div>
    );
  }

  const renderFollowUsView = () => {
    return (
      <div className="wp_followView">
        <label className="wp_followTitleStyle">{t('Follow us')}</label>
        <div className="wp_followContentView">
          {renderFollowUsItemView(Facebook, t('Facebook'), '')}
          {renderFollowUsItemView(Insta, t('Instagram'), '')}
          {renderFollowUsItemView(Twitter, t('Twitter'), '')}
          {renderFollowUsItemView(YouTube, t('Youtube'), '')}
          {renderFollowUsItemView(LinkedInLight, t('Linkedin'), '')}
        </div>
      </div>
    );
  }

  const renderContactView = () => {
    return (
      <div className="wp_contactView">
        {renderContactTopView()}
        <div className="wp_separatorStyle"></div>
        <div className="wp_contactBottomView">
          {renderContactDetailsView()}          
        </div>
      </div>
    );
  }

  const renderFooterView = () => {
    return (
      <div className={'wp_footerView'}>
        <label className={'wp_footerTextStyle'}>{t('Copyright Info')}</label>
      </div>
    );
  }

  const renderContentView = () => {
    return (
      <div className="wp_content">
        {renderHeaderView()}
        {renderBannersView()}
        {activeView === 'InvestorCharter' && renderInvestorCharterView()}
        {activeView === 'GrievanceRedressal' && renderGrievanceRedressalView()}
        {activeView === 'ComplaintsHistory' && renderComplaintsHistoryView()}
        {(!activeView || !['InvestorCharter', 'GrievanceRedressal', 'ComplaintsHistory'].includes(activeView)) && (
    <>
      {renderServicesView()}
      {renderTestScoreView()}
      {renderAboutUsView()}
      {renderTeamView()}
      {renderTestimonialsView()}
      </>
  )}
  {renderContactView()}
  {renderFooterView()}
</div>
      
    );
  }

  const renderEnquiryModal = () => {
    return (
      <EnquiryModal
        showModal={state.showEnquiryModal}
        hideModal={hideEnquiryModal}
      />
    );
  }

  const renderReportModal = () => {
    return (
      <ReportModal
        showModal={state.showReportModal}
        hideModal={hideReportModal}
      />
    );
  }

  const renderInsuranceScoreModal = () => {
    return (
      <InsuranceScoreModal
        showModal={state.showInsuranceScoreModal}
        hideModal={hideInsuranceScoreModal}
      />
    );
  }


  const renderRRScoreModal = () => {
    return (
      <RRScoreModal
        showModal={state.showRRScoreModal}
        hideModal={hideRRScoreModal}
      />
    );
  }

  const renderSustainScoreModal = () => {
    return (
      <SustainabilityScoreModal
        showModal={state.showSustainScoreModal}
        hideModal={hideSustainScoreModal}
      />
    );
  }

  const renderContactModal = () => {
    return (
      <ContactModal
        showModal={state.showContactModal}
        hideModal={hideContactModal}
      />
    );
  }

  return (
    <div className={"wp_container"}>
      {renderContentView()}
      {state.showEnquiryModal && renderEnquiryModal()}
      {state.showReportModal && renderReportModal()}
      {state.showInsuranceScoreModal && renderInsuranceScoreModal()}
      {state.showRRScoreModal && renderRRScoreModal()}
      {state.showSustainScoreModal && renderSustainScoreModal()}
      {state.showContactModal && renderContactModal()}
    </div>
  );
}

export default WebPageScrn;